import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    frontUser: null,
    alerts: [],
    plans: [],
    orders: [],
    recieved_orders: [],
    payments: [],
    deposits: [],
    posts: [],
    own_posts: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },

  // locale
  setFrontUser(state, frontUser) {
    state.frontUser = frontUser
  },

  addFollowing(state, id){
    state.frontUser.following_user_ids.push(id)
    state.frontUser.following_user_ids = _.uniq(state.frontUser.following_user_ids);
  },
  deleteFollowing(state, id){
    state.frontUser.following_user_ids = _.filter(state.frontUser.following_user_ids, (f_id) => { return f_id !== id })
  },
}
export const getters = {
}

// 戻り地はisBeforeRequest
export const actions = {
  async getFrontUser(context){
    await httpClient
    .get('/frt/account.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setFrontUser", res.data.data.front_user)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },
}
