import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import Compressor from 'compressorjs'
import { isDev } from '~/utils/env'

export default {
  computed: {
    isDev(){
      return isDev()
    },
    ...mapState({
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isLogin: state => !!state.auth.authToken,
      auth: state => state.auth,

      frontUser: state => state.frontUser.frontUser,
      new_alert_size: state => state.frontUser.new_alert_size,
      prv_alerts: state => state.frontUser.alerts,
      prv_plans: state => state.frontUser.plans,
      prv_orders: state => state.frontUser.orders,
      prv_recieved_orders: state => state.frontUser.recieved_orders,
      prv_payments: state => state.frontUser.payments,
      prv_deposits: state => state.frontUser.deposits,
      prv_posts: state => state.frontUser.posts,
      prv_own_posts: state => state.frontUser.own_posts,

      pub_plans: state => state.media.plans,
      pub_posts: state => state.media.posts,
      pub_front_users: state => state.media.front_users,

    }),
  },
  methods: {
    linktTo(path){
      this.$router.push(pash)
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    autoLink(text) {
      return _.isString(text) ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>") : '';
    },
    getActionLimit(order, isClient){
      if(isClient){
        if(order.status === "order_requested") return "-"
        if(order.status === "order_canceled") return "-"
        if(order.status === "fix_requested") return dayjs(order.fix_requested_at).add(4, "day").format("YYYY/MM/DD(ddd)")  
        if(order.status === "request_fixed") return "-"
        if(order.status === "request_accepted") return dayjs(order.request_accepted_at).add(29, "day").format("YYYY/MM/DD(ddd)")
        if(order.status === "request_denied") return "-"
        if(order.status === "request_auto_denied") return "-"
        if(order.status === "delivered") return "-"
        if(order.status === "deliver_fixed") return "-"
        if(order.status === "deliver_auto_canceled") "-"
        if(order.status === "deliver_accepted") "-"
        if(order.status === "deliver_auto_accepted") "-"
      }else{
        if(order.status === "order_requested") return dayjs(order.requested_at).add(4, "day").format("YYYY/MM/DD(ddd)")  
        if(order.status === "order_canceled") return "-"
        if(order.status === "fix_requested") return "-"
        if(order.status === "request_fixed") return dayjs(order.fix_requested_at).add(4, "day").format("YYYY/MM/DD(ddd)")
        if(order.status === "request_accepted") return dayjs(order.request_accepted_at).add(29, "day").format("YYYY/MM/DD(ddd)")
        if(order.status === "request_denied") return "-"
        if(order.status === "request_auto_denied") return "-"
        if(order.status === "delivered") return "-"
        if(order.status === "deliver_fixed") return "-"
        if(order.status === "deliver_auto_canceled") "-"
        if(order.status === "deliver_accepted") "-"
        if(order.status === "deliver_auto_accepted") "-"
      }
    },

    orderStatusText(status, isClient){
      if(isClient){
        if(status === "order_requested") return "リクエスト確認中"
        if(status === "order_canceled") return "キャンセル済み"
        if(status === "fix_requested") return "リクエスト修正 要確認"
        if(status === "request_fixed") return "修正リクエスト確認中"
        if(status === "request_accepted") return "制作中"
        if(status === "request_denied") return "承認拒否"
        if(status === "request_auto_denied") return "承認拒否(期限切れ)"
        if(status === "delivered") return "納品済み"
        if(status === "deliver_fixed") return "修正納品済み"
        if(status === "deliver_auto_canceled") return "納品期限切れ"
        if(status === "deliver_accepted") return "納品確認済み"
        if(status === "deliver_auto_accepted") return "納品確認済み(自動)"
      }else{
        if(status === "order_requested") return "リクエスト 要確認"
        if(status === "order_canceled") return "キャンセル済み"
        if(status === "fix_requested") return "リクエスト修正依頼中"
        if(status === "request_fixed") return "修正リクエスト 要確認"
        if(status === "request_accepted") return "制作中"
        if(status === "request_denied") return "承認拒否"
        if(status === "request_auto_denied") return "承認拒否(期限切れ)"
        if(status === "delivered") return "納品済み"
        if(status === "deliver_fixed") return "修正納品済み"
        if(status === "deliver_auto_canceled") return "納品期限切れ"
        if(status === "deliver_accepted") return "納品確認済み"
        if(status === "deliver_auto_accepted") return "納品確認済み(自動)"
      }
    },
    orderStatusColor(status, isClient){
      let blue = "#0d6efd"
      let red = "#dc3545"
      let orange = "#fd7e14"
      let yellow = "#ffc107"
      let green = "#198754"
      let gray = "gray"
      if(isClient){
        if(status === "order_requested") return blue
        if(status === "order_canceled") return gray
        if(status === "fix_requested") return red
        if(status === "request_fixed") return blue
        if(status === "request_accepted") return green
        if(status === "request_denied") return gray
        if(status === "request_auto_denied") return gray
        if(status === "delivered") return green
        if(status === "deliver_fixed") return green
        if(status === "deliver_auto_canceled") return gray
        if(status === "deliver_accepted") return green 
        if(status === "deliver_auto_accepted") return green
      }else{
        if(status === "order_requested") return red
        if(status === "order_canceled") return gray
        if(status === "fix_requested") return blue
        if(status === "request_fixed") return red
        if(status === "request_accepted") return red
        if(status === "request_denied") return gray
        if(status === "request_auto_denied") return gray
        if(status === "delivered") return green
        if(status === "deliver_fixed") return green
        if(status === "deliver_auto_canceled") return gray
        if(status === "deliver_accepted") return green
        if(status === "deliver_auto_accepted") return green
      }
    },
    paymentStatus(status){
      if(status === "reserved") return "支払い予約中"
      if(status === "paied") return "支払い済み"
      if(status === "canceled") return "返金済み"
      if(status === "fail") return "支払い失敗"
      if(status === "fix_paied") return "支払い改修済み"
      if(status === "cancel_fail") return "返金失敗"
    },
  }
}
