//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    hasBack: {type: Boolean, default: false },
    backUrl: String,
  },
  methods: {
    goBack(){
      if(this.backUrl){
        this.$router.push(this.backUrl)
      }else{
        this.$router.go(-1)
      }
    },
  }
}
