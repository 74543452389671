import Vue from 'vue'
import { isDev } from '~/utils/env'

let appConfigs = {}
if (isDev()) {
  // appConfigs.customerUrl = "http://localhost:3000"
  appConfigs.payPublicKey = "pk_test_51LkmFwLGl5dwajN23D37GJIIQo3fvnmZtC31jHaOQ9EN8en0eFDPF8VFHXJJdd2z0Xx8CrpymcGbjTEPKAKZ71GF00GSiNm5fA"
} else {
  // appConfigs.customerUrl = "https://cus.spde.menu"
  appConfigs.payPublicKey = "pk_live_51LkmFwLGl5dwajN2mZWN2HiAVXbg8B2GZARioYVa9UgZDNMoGPpasDxKJBJx7g3smri3CbcJvMhPi79Ek94gusCM00Y9ejP273"
}

// 共通
// appConfigs.cloudinaryApiKey = "239742381986822"


Vue.prototype.$appConfigs = appConfigs