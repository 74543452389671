import Vue from "vue"
import ActionCable from 'actioncable';
import { isDev } from '~/utils/env'
const ws_url = isDev() ? 'ws://crowler-dev.com:8888/cable' : 'wss://nigaoe-api.saucer-saas.com/cable'
const cable = ActionCable.createConsumer(ws_url);
Vue.prototype.$cable = cable;

export default async ({ route, store }) => {
  // 数秒後に接続
  window.setTimeout(async () => {
    // store.dispatch("actionCable/channelSubscribe", "ShopChannel")
  }, 1000 * 3);
}