export const CommonAppHeader = () => import('../../components/common/AppHeader.vue' /* webpackChunkName: "components/common-app-header" */).then(c => wrapFunctional(c.default || c))
export const CommonCard = () => import('../../components/common/Card.vue' /* webpackChunkName: "components/common-card" */).then(c => wrapFunctional(c.default || c))
export const CommonDatePicker = () => import('../../components/common/DatePicker.vue' /* webpackChunkName: "components/common-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonDocTabs = () => import('../../components/common/DocTabs.vue' /* webpackChunkName: "components/common-doc-tabs" */).then(c => wrapFunctional(c.default || c))
export const CommonFollowPart = () => import('../../components/common/FollowPart.vue' /* webpackChunkName: "components/common-follow-part" */).then(c => wrapFunctional(c.default || c))
export const CommonImageFileInput = () => import('../../components/common/ImageFileInput.vue' /* webpackChunkName: "components/common-image-file-input" */).then(c => wrapFunctional(c.default || c))
export const CommonImageThumbnail = () => import('../../components/common/ImageThumbnail.vue' /* webpackChunkName: "components/common-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonPageHeader = () => import('../../components/common/PageHeader.vue' /* webpackChunkName: "components/common-page-header" */).then(c => wrapFunctional(c.default || c))
export const CommonRequestPart = () => import('../../components/common/RequestPart.vue' /* webpackChunkName: "components/common-request-part" */).then(c => wrapFunctional(c.default || c))
export const CommonUserIcon = () => import('../../components/common/UserIcon.vue' /* webpackChunkName: "components/common-user-icon" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
