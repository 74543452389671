import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ea3426b8 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _47f81103 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _54d9ff21 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5dcee496 = () => interopDefault(import('../pages/account/alerts/index.vue' /* webpackChunkName: "pages/account/alerts/index" */))
const _78acad6b = () => interopDefault(import('../pages/account/card.vue' /* webpackChunkName: "pages/account/card" */))
const _04cb5e73 = () => interopDefault(import('../pages/account/deposits/index.vue' /* webpackChunkName: "pages/account/deposits/index" */))
const _ba2a2ec8 = () => interopDefault(import('../pages/account/edit_creater.vue' /* webpackChunkName: "pages/account/edit_creater" */))
const _4eb0d936 = () => interopDefault(import('../pages/account/edit.vue' /* webpackChunkName: "pages/account/edit" */))
const _2c8740d1 = () => interopDefault(import('../pages/account/email.vue' /* webpackChunkName: "pages/account/email" */))
const _344ec483 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _02d0d414 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _fc5adbea = () => interopDefault(import('../pages/account/payments/index.vue' /* webpackChunkName: "pages/account/payments/index" */))
const _71230be2 = () => interopDefault(import('../pages/account/plans/index.vue' /* webpackChunkName: "pages/account/plans/index" */))
const _0a1cbe0b = () => interopDefault(import('../pages/account/posts/index.vue' /* webpackChunkName: "pages/account/posts/index" */))
const _4e43b173 = () => interopDefault(import('../pages/account/recieved_orders/index.vue' /* webpackChunkName: "pages/account/recieved_orders/index" */))
const _87f6ef32 = () => interopDefault(import('../pages/docs/client_guideline.vue' /* webpackChunkName: "pages/docs/client_guideline" */))
const _a9e1fbe8 = () => interopDefault(import('../pages/docs/creater_guideline.vue' /* webpackChunkName: "pages/docs/creater_guideline" */))
const _71856e07 = () => interopDefault(import('../pages/docs/law.vue' /* webpackChunkName: "pages/docs/law" */))
const _66cd0052 = () => interopDefault(import('../pages/docs/term.vue' /* webpackChunkName: "pages/docs/term" */))
const _355ce62c = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _c42a73d0 = () => interopDefault(import('../pages/login/re_password.vue' /* webpackChunkName: "pages/login/re_password" */))
const _30b16e64 = () => interopDefault(import('../pages/login/sign_up.vue' /* webpackChunkName: "pages/login/sign_up" */))
const _57c718a0 = () => interopDefault(import('../pages/account/plans/new.vue' /* webpackChunkName: "pages/account/plans/new" */))
const _1cfe9c19 = () => interopDefault(import('../pages/account/posts/new.vue' /* webpackChunkName: "pages/account/posts/new" */))
const _12b2ac70 = () => interopDefault(import('../pages/account/orders/_uid.vue' /* webpackChunkName: "pages/account/orders/_uid" */))
const _5acf059e = () => interopDefault(import('../pages/account/plans/_uid.vue' /* webpackChunkName: "pages/account/plans/_uid" */))
const _b4093c30 = () => interopDefault(import('../pages/account/posts/_uid.vue' /* webpackChunkName: "pages/account/posts/_uid" */))
const _7e173d00 = () => interopDefault(import('../pages/account/recieved_orders/_uid.vue' /* webpackChunkName: "pages/account/recieved_orders/_uid" */))
const _71d91ddb = () => interopDefault(import('../pages/creaters/_search_type.vue' /* webpackChunkName: "pages/creaters/_search_type" */))
const _1b362b51 = () => interopDefault(import('../pages/plan/_uid/index.vue' /* webpackChunkName: "pages/plan/_uid/index" */))
const _133a0092 = () => interopDefault(import('../pages/post/_uid.vue' /* webpackChunkName: "pages/post/_uid" */))
const _c00e6932 = () => interopDefault(import('../pages/posts/_search_type.vue' /* webpackChunkName: "pages/posts/_search_type" */))
const _9eb7e628 = () => interopDefault(import('../pages/user/_uid.vue' /* webpackChunkName: "pages/user/_uid" */))
const _aac4712e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _ea3426b8,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/login/",
    component: _47f81103,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/search/",
    component: _54d9ff21,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/account/alerts/",
    component: _5dcee496,
    pathToRegexpOptions: {"strict":true},
    name: "account-alerts"
  }, {
    path: "/account/card/",
    component: _78acad6b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card"
  }, {
    path: "/account/deposits/",
    component: _04cb5e73,
    pathToRegexpOptions: {"strict":true},
    name: "account-deposits"
  }, {
    path: "/account/edit_creater/",
    component: _ba2a2ec8,
    pathToRegexpOptions: {"strict":true},
    name: "account-edit_creater"
  }, {
    path: "/account/edit/",
    component: _4eb0d936,
    pathToRegexpOptions: {"strict":true},
    name: "account-edit"
  }, {
    path: "/account/email/",
    component: _2c8740d1,
    pathToRegexpOptions: {"strict":true},
    name: "account-email"
  }, {
    path: "/account/orders/",
    component: _344ec483,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders"
  }, {
    path: "/account/password/",
    component: _02d0d414,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/account/payments/",
    component: _fc5adbea,
    pathToRegexpOptions: {"strict":true},
    name: "account-payments"
  }, {
    path: "/account/plans/",
    component: _71230be2,
    pathToRegexpOptions: {"strict":true},
    name: "account-plans"
  }, {
    path: "/account/posts/",
    component: _0a1cbe0b,
    pathToRegexpOptions: {"strict":true},
    name: "account-posts"
  }, {
    path: "/account/recieved_orders/",
    component: _4e43b173,
    pathToRegexpOptions: {"strict":true},
    name: "account-recieved_orders"
  }, {
    path: "/docs/client_guideline/",
    component: _87f6ef32,
    pathToRegexpOptions: {"strict":true},
    name: "docs-client_guideline"
  }, {
    path: "/docs/creater_guideline/",
    component: _a9e1fbe8,
    pathToRegexpOptions: {"strict":true},
    name: "docs-creater_guideline"
  }, {
    path: "/docs/law/",
    component: _71856e07,
    pathToRegexpOptions: {"strict":true},
    name: "docs-law"
  }, {
    path: "/docs/term/",
    component: _66cd0052,
    pathToRegexpOptions: {"strict":true},
    name: "docs-term"
  }, {
    path: "/login/deputy/",
    component: _355ce62c,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/login/re_password/",
    component: _c42a73d0,
    pathToRegexpOptions: {"strict":true},
    name: "login-re_password"
  }, {
    path: "/login/sign_up/",
    component: _30b16e64,
    pathToRegexpOptions: {"strict":true},
    name: "login-sign_up"
  }, {
    path: "/account/plans/new/",
    component: _57c718a0,
    pathToRegexpOptions: {"strict":true},
    name: "account-plans-new"
  }, {
    path: "/account/posts/new/",
    component: _1cfe9c19,
    pathToRegexpOptions: {"strict":true},
    name: "account-posts-new"
  }, {
    path: "/account/orders/:uid/",
    component: _12b2ac70,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders-uid"
  }, {
    path: "/account/plans/:uid/",
    component: _5acf059e,
    pathToRegexpOptions: {"strict":true},
    name: "account-plans-uid"
  }, {
    path: "/account/posts/:uid/",
    component: _b4093c30,
    pathToRegexpOptions: {"strict":true},
    name: "account-posts-uid"
  }, {
    path: "/account/recieved_orders/:uid/",
    component: _7e173d00,
    pathToRegexpOptions: {"strict":true},
    name: "account-recieved_orders-uid"
  }, {
    path: "/creaters/:search_type?/",
    component: _71d91ddb,
    pathToRegexpOptions: {"strict":true},
    name: "creaters-search_type"
  }, {
    path: "/plan/:uid?/",
    component: _1b362b51,
    pathToRegexpOptions: {"strict":true},
    name: "plan-uid"
  }, {
    path: "/post/:uid?/",
    component: _133a0092,
    pathToRegexpOptions: {"strict":true},
    name: "post-uid"
  }, {
    path: "/posts/:search_type?/",
    component: _c00e6932,
    pathToRegexpOptions: {"strict":true},
    name: "posts-search_type"
  }, {
    path: "/user/:uid?/",
    component: _9eb7e628,
    pathToRegexpOptions: {"strict":true},
    name: "user-uid"
  }, {
    path: "/",
    component: _aac4712e,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
