import https from 'https'
import axios from 'axios'
import { isDev } from '~/utils/env'

let options = {}
if (isDev()) {
  // options = {
  //   baseURL: 'https://api.crowler-dev.com',
  //   // Error: self signed certificate 対策
  //   httpsAgent: new https.Agent({
  //     rejectUnauthorized: false
  //   })
  // }
  options = {
    baseURL: 'http://api.crowler-dev.com:8888',
    // baseURL: 'https://api.crowler-dev.com',
    // baseURL: 'http://dev-api.saucer-saas.com:8888',
  }
} else {
  options = {
    // baseURL: 'https://dev-api.saucer-saas.com'
    baseURL: 'https://nigaoe-api.saucer-saas.com',
  }
}
const httpClient = axios.create(options)
export { httpClient }