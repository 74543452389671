import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    posts: [],
    plans: [],
    front_users: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  resetAll(state){
    Object.assign(state, getDefaultState())
  },
}
export const getters = {
}

// 戻り地はisBeforeRequest
export const actions = {
}
