import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    // auth
    authToken: null,
 
    // push
    oneSignalDeviseToken: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  logout(state) {
    // action cable disconnect
    // window.storeCtl.dispatch("actionCable/removeSpecifiedChannel", "ShopChannel")

    window.storeCtl.commit("frontUser/resetAll")
    $nuxt.$router.push(`/`)
    Object.assign(state, getDefaultState())

  },

  // push
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },
}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
}

// 戻り地はisBeforeRequest
export const actions = {
  async login(context, payload){
    let self = this
    window.storeCtl.commit("loading/start")
    let res = await httpClient
    .post('/frt/account/login.json', {
      email: payload.email,
      password: payload.password,
      confirm_token: payload.confirm_token,
    })
    .then(async (res) => {
      if (res.data.status === 'success') {

        window.storeCtl.commit('auth/setAuthToken', res.data.data.auth_token)
        window.storeCtl.commit('frontUser/setFrontUser', res.data.data.front_user)
        
        $nuxt.$router.push('/')
        return true
      } else {
        window.storeCtl.commit("loading/stop")
        window.storeCtl.commit("alert/setError", res.data.message)
        return true
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
    return res
  },

  //// push
  async updateOneSignalDeviseToken(context, oneSignalDeviseToken){
    context.commit('setOneSignalDeviseToken', oneSignalDeviseToken)
    let url, options = null
    if(context.state.authToken){
      url = '/shop/account/update_device_token.json'
      options = {onesignal_device_token: oneSignalDeviseToken}
      if(context.state.oneSignalDeviseToken && context.state.loginDeviseTokenDone) return
    }
    console.log("updateOneSignalDeviseToken")
    await httpClient
    .post(url, options)
    .then((res) => {
      if (res.data.status === 'success') {
        if(context.state.authToken){ 
          context.commit('setLoginDeviseTokenDone')
        }else{
          context.commit('setGuestDeviseTokenDone')
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },
}
