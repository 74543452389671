export default function({ store, redirect, route }) {
  if(!store.getters['auth/isLogin']){
    console.log("in logout")
    store.commit("devise/setLoginRedirectPath", route.path)
    setTimeout(function() {
      window.storeCtl.commit("alert/setError", "ログインしてください。")
    }, 500);
    return redirect('/login/sign_up/')
  }
}
