import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { sleep } from '~/utils/tool'

const checkNewOrderPrint = (old_order_sets, new_order_sets) => {
  let diff_order_sets = []
  if(old_order_sets.length === 0){
    // OLDなければ一旦全部DIFF
    diff_order_sets = new_order_sets
  }{
    // console.log("old_order_sets > 0")
    let latest_old_order_set = _.orderBy(old_order_sets, 'created_at', 'desc')[0]
    let old_order_sets_ids = _.map(old_order_sets, 'id');
    // console.log("latest_old_order_set", latest_old_order_set)
    // console.log("old_order_sets_ids", old_order_sets_ids)
    new_order_sets.forEach(nos => {
      // OLDに含まれなくてOLDの最新版よりもIDが若い
      if(!old_order_sets_ids.includes(nos.id) && nos.id > latest_old_order_set.id){
        diff_order_sets.push(nos)
      }
    })
  }
  // console.log("diff_order_sets_1", diff_order_sets)
  // 1分以内のもの
  let base_time = dayjs().subtract(10, 'm')
  diff_order_sets = _.filter(diff_order_sets, os => {
    // console.log("base_time", base_time, os.created_at)
    return dayjs(os.created_at).isAfter(base_time)
  })
  // console.log("diff_order_sets_2", diff_order_sets)

  // キッチンプリンタ処理
  if(window.storeCtl.state.printer.print_auto_on_kitchen === true){
    diff_order_sets.forEach(os => {
      window.storeCtl.dispatch('printer/requestPrint', {
        type: "kitchen",
        order_set: os,
      })  
    })
  }

}

const getDefaultState = () => {
  return {
    isShopChannleSubscribed: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  updateShopChannelSubscribed(state, payload) {
    state.isShopChannleSubscribed = payload
  },
}
export const actions = {
  async channelSubscribe(context, channel){
    if (window.storeCtl.state.auth.authToken === null) return
    if (channel === "ShopChannel" && context.state.isShopChannleSubscribed === true) return

    $nuxt.$cable.subscriptions.create({
      channel: channel,
      auth_token: window.storeCtl.state.auth.authToken,
    }, {
      connected: () => {
        console.log(`${channel} conected`)
        if(channel === "ShopChannel") context.commit("updateShopChannelSubscribed", true)
      },
      disconnected: () => {
        console.log(`${channel} disconnected`)
      },
      received: async (data) => {
        console.log(`${channel} received`, data)

        // // FIXME:ここに処理を書く
        // if(data.action_type === "all"){
        //   await Promise.all([
        //     window.storeCtl.dispatch('shop/getSettings'),
        //     window.storeCtl.dispatch('shop/getShopTrend'),
        //   ])
        //   await sleep(500)
        //   await Promise.all([
        //     window.storeCtl.dispatch('master/getItemMasters'),
        //     window.storeCtl.dispatch('master/getTables'),
        //   ])
        //   await sleep(500)
        //   await Promise.all([
        //       window.storeCtl.dispatch('master/getPaymentMethods'),
        //     window.storeCtl.dispatch('master/getStaffs'),
        //   ])
        //   await sleep(500)
        //   await Promise.all([
        //     window.storeCtl.dispatch('floor/getSeatingGroups'),
        //     window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"}),
        //   ])
        //   await sleep(500)
        //   await Promise.all([
        //     window.storeCtl.dispatch("floor/getOrderSets", {mode: "all", init: true}),
        //     window.storeCtl.dispatch('floor/getPayments', {init: true}),
        //   ])
        // }else if(data.action_type === "shop"){
        //   window.storeCtl.dispatch('shop/getSettings')
        //   window.storeCtl.dispatch('shop/getShopTrend')
        // }else if(data.action_type === "master"){
        //   window.storeCtl.dispatch('master/getItemMasters')
        //   window.storeCtl.dispatch('master/getTables')
        //   window.storeCtl.dispatch('master/getPaymentMethods')
        //   window.storeCtl.dispatch('master/getStaffs')
        // }else if(data.action_type === "info"){
        //   window.storeCtl.dispatch('shop/getShopTrend')
        // }else if(data.action_type === "table"){
        //   window.storeCtl.dispatch("master/getTables")
        // }else if(data.action_type === "group"){
        //   window.storeCtl.dispatch("master/getTables")
        //   window.storeCtl.dispatch("floor/getSeatingGroups")
        // }else if(data.action_type === "order"){
        //   window.storeCtl.dispatch("master/getTables")

        //   let old_order_sets = _.cloneDeep(window.storeCtl.state.floor.orderd_order_sets)
        //   await window.storeCtl.dispatch("floor/getOrderSets", {mode: "orderd_or_cooked"})
        //   let new_order_sets = _.cloneDeep(window.storeCtl.state.floor.orderd_order_sets)
        //   checkNewOrderPrint(old_order_sets, new_order_sets)

        // }else if(data.action_type === "payment"){
        //   window.storeCtl.dispatch('floor/getPayments')
        // }
      },
    })
  },

  //// チャネルの購読をとく
  removeSpecifiedChannel(context, channel) {
    // console.log('removeSpecifiedChannel channel: ' + channel);
    var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
    subscriptions.forEach((subscription) => {
      let identifier = subscription.identifier
      let obj = JSON.parse(identifier);
      // console.log("before channel", channel, obj.channel)
      if (channel == obj.channel) {
        // console.log("succes delete", channel)
        $nuxt.$cable.subscriptions.remove(subscription);
        if(channel === "ShopChannel") context.commit("updateShopChannelSubscribed", false)
      }
    })
  },
}
