export const state = () => ({
  firstContact: false,
  firstOrder: false,
  locale_code: null,
  isPC: false,
  drawer: false,
  loginRedirectPath: null,
})
export const mutations = {
  firstContactDone(state) {
    state.firstContact = true
  },
  firstOrderDone(state) {
    state.firstOrder = true
  },
  setLocale(state, locale_code) {
    state.locale_code = locale_code
  },
  setIsPc(state, isPC) {
    state.isPC = isPC
  },
  setDrawer(state, drawer) {
    state.drawer = drawer
  },  
  setLoginRedirectPath(state, path) {
    state.loginRedirectPath = path
  },  

}
