//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Common from '~/mixins/common'
import UserIcon from '~/components/common/UserIcon'
import PageHeader from '~/components/common/PageHeader'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'
import { httpClient } from '~/utils/axios'

export default {
  name: "default",
  mixins: [Common],
  components: {
    Alert,
    Loading,
    ConfirmDialog,
    UserIcon,
    PageHeader,
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm

    // ドロワーの動き
    if(window.innerWidth > 767){
      self.drawer = true
      this.$store.commit("devise/setIsPc", true)   
    }else{
      this.$store.commit("devise/setIsPc", false) 
    }

    // 言語判定
    if(this.$store.state.devise.locale_code){
      this.$i18n.setLocaleCookie(this.$store.state.devise.locale_code)
    }else{
      const lang = navigator.language.split('-')[0]
      if(lang.indexOf('en') != -1){ 
        this.$i18n.setLocaleCookie("en")
        this.$store.commit("devise/setLocale", "en")    
      }else if(lang.indexOf('zh') != -1){ 
        this.$i18n.setLocaleCookie("zh")      
        this.$store.commit("devise/setLocale", "zh")    
      }else{
        this.$i18n.setLocaleCookie("ja")
        this.$store.commit("devise/setLocale", "ja")    
      }
    }

  },
  computed: {
    currentLocale() {
      return _.find(this.$i18n.locales, ["code", this.$i18n.locale])
    },
    drawer: {
      get(){
        return this.$store.state.devise.drawer
      },
      set(newValue){
        this.$store.commit("devise/setDrawer", newValue)
      }
    },
    isAccepting: {
      get(){
        return this.$store.state.frontUser.frontUser.is_accepting
      },
      async set(newValue){
        let self = this
        let bool = newValue === "true" ? true : false
        console.log("isAccepting", bool)
        await httpClient
          .put(`/frt/account.json`, {front_user: {is_accepting: bool}})
          .then(async (res) => {
            if (res.data.status === 'success') {
              self.$store.commit("frontUser/setFrontUser", res.data.data.front_user)
              window.storeCtl.commit("alert/setSuccess", "更新しました")
            } else {
              window.storeCtl.commit("alert/setError", res.data.message)
            }
          })
      }
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.setLocaleCookie(locale)
      this.$store.commit("devise/setLocale", locale)    
      this.$router.go(0)
    },
  },
}
