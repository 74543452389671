import axios from "axios";

async function check(store){

  // ログイン時のみ対応
  if(store.getters['auth/isLogin']){
    
    // トラッキングコード同期
    store.dispatch("tracking_code/sync")

    // oneSignal
    // ログインしていて
    // LocalStrage取れていて
    // StoreとLocalstrageの値が違う
    const onesignal_user_id = localStorage.getItem('onesignal_user_id'); 
    if(
      onesignal_user_id && 
      store.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ){
      store.dispatch("auth/updateOneSignalDeviseToken", onesignal_user_id)
    }

    // 定期的に個人情報・アラート更新
    store.dispatch('frontUser/getFrontUser')

  } // ログイン 

  // アプリ・バージョンアップ
  try {
    // version.jsonファイルから最新のバージョンを取得
    const res = await axios.get("/version.json");
    const data = res.data;
    const latestVersion = data.version;

    // 環境変数から現在のバージョンを取得
    const curVersion = !!process.env.VERSION ? Number(process.env.VERSION) : 0;
    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion){
      window.location.reload(true);
    }else{
    }
  } catch(error) {
    console.error(error);
    // エラーのときはなにもしない
  }
}

// まずSettimeoutで初期チェック、その後1分毎に自動チェック
export default async ({store, route, query}) => {

  // トラッキングコードセット
  // トラッキングコードがなければOtherにセット
  if(route.name === "user-uuid"){
    store.commit("tracking_code/setCode", `c_${route.params.uuid}`)
  }else if(route.name === "post-uuid"){
    store.commit("tracking_code/setCode", `p_${route.params.uuid}`)
  }else if(route.name === "tag-name"){
    store.commit("tracking_code/setCode", `t_${route.params.name}`)
  }else if(query.tc){
    store.commit("tracking_code/setCode", query.tc)
  }else{
    store.commit("tracking_code/setCode", `other`)
  }

  // check ios
  const userAgent = navigator.userAgent
  let isIosApp = userAgent.includes("is_iOS_App") ? true : false
  let ios_obj = {
    app_name: null, 
    app_version: null,
    devise_name: null, 
    devise_version: null
  }
  if(isIosApp){
    let x_is_ios_arr = userAgent.split("__")
    ios_obj.app_name = x_is_ios_arr[1]
    ios_obj.app_version = x_is_ios_arr[2]
    ios_obj.devise_name = x_is_ios_arr[3]
    ios_obj.devise_version = x_is_ios_arr[4]
  }
  store.commit('userAgent/set', {
    isIosApp: isIosApp,
    ios_obj: ios_obj,
  })
  
  // 起動時だと処理できない箇所があったので５秒後
  window.setTimeout(async () => {
    // 諸々のチェック
    check(store)
  }, 1000 * 5);

  // 5分に1回
  window.setInterval(() => {check(store)}, 1000 * 60 * 5);
}